
import { Alert, Box, Container, Stack, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import KCSearchField from '../../components/KCSearchField';
import SearchCards from './components/SearchCards';

import { GetPublicEstablishmentSearchApiArg, LimitedEstablishment, useGetPublicEstablishmentSearchQuery } from '../../store/kcApi';
import { CalculateDistance, useUserLocation } from '../../utils/helpers';
import { EstablishmentSearchCardProps } from './components/EstablishmentSearchCard';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as RedCoffee } from '../../assets/svg/red_coffee.svg';
import KCButtonBase from '../../components/Button';
import ReactGA from 'react-ga4';
import EstablishmentSearchCardSkeleton from './components/EstablishmentSearchCardSkeleton';

const WriteReviewPage: React.FC = () => {
  const theme = useTheme();
  const [querySearch] = useSearchParams();
  const [search, setSearch] = React.useState<string>(querySearch.get("search") || "");
  const [searchParams, setSearchParams] = React.useState<GetPublicEstablishmentSearchApiArg>({});
  const [searchResults, setSearchResults] = React.useState<EstablishmentSearchCardProps[]>([]);
  const [locationAlert] = React.useState<boolean>(false);
  const { longitude, latitude } = useUserLocation();

  const handleSearch = useCallback((expanded = false) => {
    setSearchResults([])
    if (expanded) {
      ReactGA.event({
        category: "User Search Interaction",
        action: "Load More Establishments",
        label: search,
      })
    } else {
      ReactGA.event({
        category: "User Search Interaction",
        action: "Search for Establishment",
        label: search,
      })
    }
    setSearchParams({
      textSearch: search,
      longitude: longitude,
      latitude: latitude,
      expanded: expanded,
    });
    window.scrollTo(0, 0);
  }, [setSearchResults, setSearchParams, latitude, longitude, search]);

  const {
    data,
    error,
    isFetching,
    isSuccess,
  } = useGetPublicEstablishmentSearchQuery(searchParams);

  useEffect(() => {
    if (search) {
      handleSearch(false)
    }
    window.scrollTo(0, 0);
  }, [handleSearch, search]);

  useEffect(() => {
    if (data?.data && !error && !isFetching) {
      setSearchResults(data?.data?.map((item: LimitedEstablishment): EstablishmentSearchCardProps => (
        {
          estId: item.id || "",
          gid: item.google_place_id || "",
          name: item.name || "",
          address: item.address || "",
          baseRate: item.base_rate || 0,
          avgTips: item.avg_tips || 0,
          distance: CalculateDistance(
            { latitude: latitude, longitude: longitude },
            { latitude: item.location?.coordinates?.[1] || 0, longitude: item.location?.coordinates?.[0] || 0 },
          ),
          sanitationScore: "--",
          image: item.photo || "",
          starRating: item.overall_rating || 0,
          numOfReviews: item.total_reviews || 0,
        }
      )) || []);
    } else if (!data?.data && !error && !isFetching) {
      setSearchResults([]);
    }
  }, [data, isFetching, isSuccess, latitude, longitude, error]);

  useEffect(() => {
    if (!latitude || !longitude) {
      return;
    }
    // isInNYC({ latitude: latitude, longitude: longitude }) ? setLocationAlert(false) : setLocationAlert(true);
  }, [latitude, longitude]);

  return (
    <Box sx={{
      backgroundColor: "#FBF8F5",
      paddingBottom: "100px",
    }}>
      <Container sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        [theme.breakpoints.down('md')]: {
          backgroundColor: "#FBF8F5",
        },
        paddingBottom: "100px",
      }}>
        <Box sx={{
          padding: "100px 100px 50px 100px",
          [theme.breakpoints.down('md')]: {
            padding: "40px 0px 40px 0px",
          },
        }}>
          <Box>
            {locationAlert && (
              <Alert severity="info"
                sx={{
                  margin: "20px 0px",
                }}>
                Looks like your location is outside of NYC! We are currently only serving NYC establishments.
              </Alert>
            )}
            <Typography
              variant="h3"
              color="text.secondary"
              align='center'
              sx={{
                padding: "20px 0px 20px 0px",
                fontWeight: 'bold',
              }}
            >Write a review</Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              align='center'
              sx={{
                padding: "20px 0px 20px 0px",
              }}
            ><span style={{ fontWeight: "bold" }}>All reviews are anonymous.</span> Current and former employers will not be able to see who wrote a review.</Typography>
          </Box>
          <Box sx={{
            marginTop: "40px",
          }}>
            <Stack>
              <Typography variant="body1" color="text.secondary" fontWeight='bold'>Search for your employer</Typography>
              <KCSearchField
                buttontext='Search'
                outlined={true}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder='Enter your employer...'
                fullWidth
                buttonHandleClick={() => handleSearch(false)}
              />
            </Stack>
          </Box>
        </Box>
        <Box sx={{
          padding: "0px 100px 100px 100px",
          [theme.breakpoints.down('md')]: {
            padding: "0px 0px 100px 0px",
          },

        }}>
          {isFetching && (
            <Box>
              <EstablishmentSearchCardSkeleton />
              <EstablishmentSearchCardSkeleton />
              <EstablishmentSearchCardSkeleton />
            </Box>
          )}
          {searchResults.length > 0 && !isFetching && isSuccess && (
            <Box>
              <SearchCards data={searchResults} />
              <Stack justifyContent="center" alignItems="center" spacing={4} sx={{ paddingTop: "40px" }}>
                <Typography variant="body2" color="text.secondary">Can't find the establishment you are looking for?</Typography>
                <KCButtonBase
                  onClick={() => {
                    handleSearch(true);
                  }}
                  sx={{
                    width: '319px'
                  }}
                >Expand your search</KCButtonBase>
              </Stack>
            </Box>
          )}
          {searchResults.length === 0 && !isFetching && isSuccess && (
            <Box sx={{
            }}>
              <Stack justifyContent="center" alignItems="center" spacing={4}>
                <Box>
                  <RedCoffee />
                </Box>
                <Typography variant="body2" color="text.secondary">Can't find the establishment you are looking for?</Typography>
                <KCButtonBase
                  onClick={() => {
                    handleSearch(true);
                  }}
                  sx={{
                    width: '319px'
                  }}
                >Expand your search</KCButtonBase>
              </Stack>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default WriteReviewPage;
